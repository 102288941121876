import { ApiResponseModel } from '@cue/admin-shared';
import { OauthLoginRequestModel } from '../models/oauth-login-request.model';
import { OauthLoginResponseModel } from '../models/oauth-login-response.model';
import { AccountState } from '../models/account-state.model';
import { AccountSetUserRole } from '../actions/account.actions';
import { getUserRoleId } from '../selectors/account.selectors';
import { Store } from '@ngrx/store';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable, catchError, map, mergeMap, of, retryWhen, throwError, timer } from 'rxjs';
import { CONSTANTS } from '@cue/admin-constants';
import { ConfigService } from '@cue/admin-core';

@Injectable({
  providedIn: 'root',
})
export class AccountService {
  constructor(
    private configService: ConfigService,
    private http: HttpClient,
    private store: Store<AccountState>,
  ) {}

  actionExist(): Observable<any> {
    const url =
      `${this.configService.value.apiURL}` + CONSTANTS.api.prefix + '/' + CONSTANTS.paths.account + '/' + CONSTANTS.paths.accountExist;
    return this.http.post<any>(url, {}).pipe(
      retryWhen(accountRetryStrategy()),
      catchError((error) => {
        window.alert('Cannot connect to API server, check if it is available.');
        return throwError(error);
      }),
    );
  }

  actionRegister(data: any): Observable<any> {
    return this.postAction('/' + CONSTANTS.paths.account + '/' + CONSTANTS.paths.accountRegister, data);
  }

  actionLogin(data: any): Observable<any> {
    return this.postAction('/' + CONSTANTS.paths.account + '/' + CONSTANTS.paths.accountLogin, data);
  }

  actionOAuthLogin(data: OauthLoginRequestModel): Observable<ApiResponseModel<OauthLoginResponseModel>> {
    const url =
      `${this.configService.value.apiURL}` + CONSTANTS.api.prefix + '/' + CONSTANTS.paths.account + '/' + CONSTANTS.paths.accountOauthLogin;
    return this.http.post<any>(url, data);
  }

  parseJwt(token) {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split('')
        .map((c) => {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join(''),
    );
    return JSON.parse(jsonPayload);
  }

  actionLogout(): Observable<any> {
    this.setUserRoleStore(null);
    sessionStorage.removeItem('API_TOKEN');
    sessionStorage.removeItem('REFRESH_TOKEN');
    return of(1);
  }

  actionForgottenPassword(data: any): Observable<any> {
    return this.postAction('/' + CONSTANTS.paths.account + '/' + CONSTANTS.paths.accountForgottenPassword, data);
  }

  actionResetPassword(data: any): Observable<any> {
    return this.postAction('/' + CONSTANTS.paths.account + '/' + CONSTANTS.paths.accountResetPassword, data);
  }

  amILogged(): Observable<boolean> {
    try {
      const token = sessionStorage.getItem('API_TOKEN');
      const refresh_token = sessionStorage.getItem('REFRESH_TOKEN');
      const exp = token ? this.parseJwt(token).exp : null;
      const notExpired = exp ? new Date(exp * 1000) > new Date() : false;
      return of(notExpired || refresh_token != null);
    } catch {
      return of(false);
    }
  }

  licenseExist(): Observable<boolean> {
    return this.http
      .get(`${this.configService.value.apiURL}` + CONSTANTS.api.prefix + '/' + CONSTANTS.paths.account + '/certificateExist')
      .pipe(
        retryWhen(accountRetryStrategy()),
        map((x: any) => x.success),
        catchError((_) => of(false)),
      );
  }

  getUserRole(): Observable<number> {
    return this.http.get(`${this.configService.value.apiURL}` + CONSTANTS.api.prefix + '/' + CONSTANTS.paths.account + '/userRole').pipe(
      map((x: any) => {
        return x.success ? x.data : 0;
      }),
      catchError((_) => of(0)),
    );
  }

  getUserRoleStore(): Observable<number> {
    return this.store.select(getUserRoleId);
  }

  setUserRoleStore(userRoleId: number) {
    this.store.dispatch(AccountSetUserRole({ roleId: userRoleId }));
  }

  getAssistCAs(): Observable<any> {
    return this.http.get(this.configService.value.apiURL + '/api/connectionAgent/getAssistCAs');
  }

  getServerTypes(): Observable<any> {
    return this.http.get(this.configService.value.apiURL + '/api/serverType/load');
  }

  getAccountInfo(): Observable<any> {
    return this.http.get(`${this.configService.value.apiURL}` + CONSTANTS.api.prefix + '/' + CONSTANTS.paths.account + '/me');
  }

  getAdminUrl(): Observable<any> {
    return this.http.get(`${this.configService.value.apiURL}` + CONSTANTS.api.prefix + '/' + CONSTANTS.paths.account + '/adminUrl');
  }

  private postAction(urlPostfix: string, payload: any): Observable<any> {
    const url = `${this.configService.value.apiURL}` + CONSTANTS.api.prefix + urlPostfix;
    return this.http.post<any>(url, { data: payload });
  }
}

const accountRetryStrategy =
  ({
    maxRetryAttempts = 5,
    scalingDuration = 1000,
  }: {
    maxRetryAttempts?: number;
    scalingDuration?: number;
    excludedStatusCodes?: number[];
  } = {}) =>
  (attempts: Observable<any>) => {
    return attempts.pipe(
      mergeMap((error, i) => {
        const retryAttempt = i + 1;
        if (retryAttempt > maxRetryAttempts) {
          return throwError(error);
        }
        return timer(retryAttempt * scalingDuration);
      }),
    );
  };
